<template>
  <b-card title="Message History">
    <b-table
      show-empty
      id="table"
      striped
      sort-icon-left
      class="history-table"
      :fields="table.fields"
      :per-page="table.perPage"
      :current-page="table.currentPage"
      :items="items"
      :sort-by.sync="table.sortBy"
      :sort-desc.sync="table.sortDesc"
    >
      <template v-slot:cell(eventName)="row">
        <b-link :href="'/raffle?id=' + row.item.eventId">
          {{ row.item.eventName }}
        </b-link>
      </template>

      <template v-slot:cell(status)="data">
        <b-badge variant="info" v-if="data.value === 'Started'">Started</b-badge>
        <b-badge variant="success" v-else-if="data.value === 'Completed'">Completed</b-badge>
      </template>

      <template v-slot:cell(completedAt)="data">
        <DateTimeDisplay v-if="data.value" :date-time="data.value" :time-zone="getBrowserTimeZone" />
      </template>

      <template v-slot:table-busy>
        <div class="text-center">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
    </b-table>

    <b-pagination
      v-model="table.currentPage"
      :total-rows="table.rows"
      :per-page="table.perPage"
      pills
      aria-controls="table"
      align="center"
      first-text="First"
      prev-text="Prev"
      next-text="Next"
      last-text="Last"
    />
  </b-card>
</template>

<script>
import DateTimeDisplay from '@/components/ui/DateTimeDisplay';
import MessageCenter from '@/lib/message-center';

export default {
  components: { DateTimeDisplay },
  props: {
    organization: Object
  },
  watch: {
    'form.input': function () {
      this.refreshTable();
    },
    'form.filter': function () {
      this.refreshTable();
    },
    'table.sortBy': function () {
      this.refreshTable();
    },
    organization: function () {
      this.refreshTable();
    }
  },
  data() {
    return {
      table: {
        isBusy: false,
        fields: [
          { key: 'eventName', label: 'Raffle' },
          { key: 'scheduleName', label: 'Schedule' },
          { key: 'type', label: 'Email Template' },
          { key: 'id', label: 'Campaign ID' },
          {
            key: 'notifiedContactCount',
            label: 'Contacts',
            formatter: (val) => {
              return val === null || val === undefined ? 'TBD' : val;
            }
          },
          { key: 'status' },
          {
            key: 'completedAt',
            label: 'Date Completed'
          }
        ],
        rows: 0,
        currentPage: 1,
        perPage: 8
      }
    };
  },
  computed: {
    getBrowserTimeZone() {
      // This displays UTC using the User's browser Time Zone infomation. It does not work for IE.
      // https://caniuse.com/mdn-javascript_builtins_intl_datetimeformat_format
      return Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'America/Halifax';
    }
  },
  methods: {
    refreshTable() {
      this.$root.$emit('bv::refresh::table', 'table');
    },
    async items() {
      try {
        const params = {
          pageNumber: this.table.currentPage,
          pageSize: this.table.perPage,
          ...(this.organization && { organizationId: this.organization.id })
        };

        const result = await MessageCenter.getHistory(params);

        this.table.rows = result.count;

        return result.histories;
      } catch (error) {
        this.$emit('error', `Failed to load message history ${error.message}`);
      }
    }
  }
};
</script>

<style scoped>
.container {
  text-align: left;
  margin-top: 20px;
  margin-left: 115px;
}
</style>
