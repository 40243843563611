<template>
  <div class="flex flex-wrap items-start">
    <b-alert v-if="error" class="w-100 basis-full" show dismissible variant="danger">{{ error }}</b-alert>
    <multiselect
      v-model="selected"
      :options="options"
      label="description"
      track-by="description"
      :loading="loading"
      :allow-empty="true"
      :multiple="true"
      :close-on-select="false"
      :clear-on-select="false"
      placeholder="Select schedules you wish to add"
      name="schedule-select"
      class="flex-1 lg:max-w-1/2 mr-4"
    >
      <template #noResult> No schedules found. </template>
    </multiselect>
    <Button variant="green" :loading="loading" @onClick="submitSchedules">Add schedule</Button>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import MessageCenter from '@/lib/message-center';

import Button from '@/components/ui/LoadingButton';

export default {
  components: { Multiselect, Button },
  props: {
    organizationId: {
      type: String,
      required: true
    },
    reload: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      error: null,
      loading: false,
      options: [],
      selected: [],
      initialized: false
    };
  },
  watch: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    reload(_newVal, _oldVal) {
      this.loadCurrent();
    }
  },
  async mounted() {
    this.loadCurrent();
  },
  methods: {
    async loadCurrent() {
      this.loading = true;
      try {
        const params = {
          organizationId: this.organizationId
        };
        // Get Org's schedules.
        const options = await MessageCenter.listSchedules(params);

        // Filter out active schedules
        const availableSchedules = options.filter((schedules) => {
          return schedules.active === false;
        });

        this.options = availableSchedules;
      } catch (error) {
        this.error = `Failed to load schedules ${error.message}`;
      }

      this.loading = false;
    },
    async submitSchedules() {
      if (this.selected.length) {
        this.loading = true;
        const addedScheduleNames = this.selected.map((item) => {
          return item.name;
        });

        try {
          await MessageCenter.updateSchedule(this.organizationId, { messageCenterScheduleName: addedScheduleNames });
          this.selected = [];
          await this.loadCurrent();
          this.$emit('schedulesUpdated');
        } catch (error) {
          this.error = `Failed to update schedules, ${this.parseError(error).message}`;
        } finally {
          this.loading = false;
        }
      }
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tag {
  padding: 0.5rem 1.5rem 0.5rem 1rem;
  background-color: #439547 !important;
}
.multiselect__option--highlight {
  background-color: #439547 !important;
}
</style>
