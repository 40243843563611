<template>
  <div>
    <b-modal
      id="send-email-modal"
      v-model="modalShow"
      title="Confirm Email Send"
      @ok="handleOk"
      no-close-on-backdrop
      @show="getContactsCount"
      body-class="position-static"
    >
      <b-overlay :show="loading" no-wrap rounded="sm" />
      <b-alert variant="error" v-if="errorMessage">{{ errorMessage }}</b-alert>
      <b-form @submit.stop.prevent="onSubmit">
        <div v-if="event && organization">
          <div><strong>Organization: </strong>{{ organization.name }}</div>
          <div><strong>Event: </strong>{{ event.name }}</div>
          <div v-if="template.value === 'eventMemberLink'">
            <strong>Event member: </strong> {{ eventMember ? eventMember.name : 'All' }}
          </div>
          <div><strong>Template: </strong>{{ template.niceName }}</div>
          <div><strong>Number of contacts: </strong>{{ contactsCount }}</div>
          <!-- This option cannot be re-enabled until the MC backend can support it using Postgres -->
          <!-- <div v-if="template.value !== 'eventMemberLink'">
              <strong>Use custom event member links: </strong>{{ settings.useCustomEventMemberLinks ? 'Yes' : 'No' }}
            </div> -->
          <div v-if="template.value === 'winnerPicked'">
            <strong>Include next raffle link: </strong>{{ settings.includeNextRaffleLink ? 'Yes' : 'No' }}
          </div>
          <div v-if="settings.includeNextRaffleLink">
            <strong>Next raffle link: </strong>{{ settings.nextRaffleLink }}
          </div>
          <div v-if="template.value === 'raffleReminder'">
            <strong>Exclude customers who have already purchased for the event: </strong>
            {{ settings.omitPurchasedForEvent ? 'Yes' : 'No' }}
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import MessageCenter from '@/lib/message-center';
import EventServiceV2 from '@/lib/event-service-v2';

export default {
  props: ['event', 'eventMember', 'organization', 'template', 'settings'],
  data() {
    return {
      modalShow: false,
      contactsCount: 0,
      loading: false,
      errorMessage: ''
    };
  },
  methods: {
    handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    },

    async getContactsCount() {
      this.loading = true;
      try {
        if (this.organization.id) {
          if (this.template.value === 'eventMemberLink') {
            if (this.eventMember.id) {
              this.contactsCount = 1;
            } else {
              const result = await EventServiceV2.listEventMembers({
                eventId: this.event?.id,
                page: 0,
                pageSize: 1
              });

              this.contactsCount = result.pagination.total;
            }
          } else {
            const params = {
              eventId: this.event?.id,
              purchasedForEvent: this.settings.omitPurchasedForEvent,
              // currently both event reminder and winner emails respect unsubs
              omitUnsubscribed: true
            };

            const result = await MessageCenter.getContacts(this.organization.id, params);
            this.contactsCount = result.count;
          }
        }
      } catch (error) {
        const errorMessage = error.response && error.response.data.error ? error.response.data.error.message : error;
        this.errorMessage = `Failed to get contacts for organization: ${errorMessage}`;

        this.contactsCount = 0;
      }

      this.loading = false;
    },

    async onSubmit() {
      try {
        if (this.template.value === 'raffleReminder') {
          await MessageCenter.sendRaffleReminder(
            this.event.id,
            this.organization.id,
            this.settings.useCustomEventMemberLinks,
            this.settings.omitPurchasedForEvent
          );
        } else if (this.template.value === 'winnerPicked') {
          await MessageCenter.sendWinnerPicked(
            this.event.id,
            this.organization.id,
            this.settings.useCustomEventMemberLinks,
            this.settings.includeNextRaffleLink,
            this.settings.nextRaffleLink
          );
        } else if (this.template.value === 'eventMemberLink') {
          await MessageCenter.sendEventMemberLink(this.event.id, this.eventMember.id);
        }

        this.$emit('success', 'Emails are being sent!');
      } catch (error) {
        this.$emit('error', 'Failed to send emails');
      }

      this.modalShow = false;
    }
  }
};
</script>

<style scoped></style>
