import axios from '@/axios';
import config from '@/config';

const baseURL = config.MESSAGE_CENTER_URL;

export default {
  sendRaffleReminder: async (eventId, organizationId, useCustomEventMemberLinks, purchasedForEvent) => {
    const body = {
      eventId: eventId,
      organizationId: organizationId,
      useCustomEventMemberLinks: useCustomEventMemberLinks,
      purchasedForEvent
    };

    const response = await axios.post('/messenger/raffle-reminder/send', body, { baseURL });

    return response.data.data;
  },
  sendTestRaffleReminder: async (eventId, emailAddress) => {
    const body = {
      eventId: eventId,
      emailAddress: emailAddress
    };

    const response = await axios.post('/messenger/raffle-reminder/test', body, { baseURL });

    return response.data.data;
  },
  sendWinnerPicked: async (
    eventId,
    organizationId,
    useCustomEventMemberLinks,
    includeNextRaffleLink,
    nextRaffleLink
  ) => {
    const body = {
      eventId: eventId,
      organizationId: organizationId,
      useCustomEventMemberLinks: useCustomEventMemberLinks,
      includeNextRaffleLink: includeNextRaffleLink,
      nextRaffleLink: nextRaffleLink
    };

    const response = await axios.post('/messenger/winner-picked/send', body, { baseURL });

    return response.data.data;
  },
  sendTestWinnerPicked: async (eventId, emailAddress, includeNextRaffleLink, nextRaffleLink) => {
    const body = {
      eventId: eventId,
      emailAddress: emailAddress,
      includeNextRaffleLink: includeNextRaffleLink,
      nextRaffleLink: nextRaffleLink
    };

    const response = await axios.post('/messenger/winner-picked/test', body, { baseURL });

    return response.data.data;
  },
  sendEventMemberLink: async (eventId, eventMemberId) => {
    const body = {
      eventId
    };

    if (eventMemberId) {
      body.eventMemberId = eventMemberId;
    }

    const response = await axios.post('/messenger/event-member-link/send', body, { baseURL });

    return response.data.data;
  },
  sendTestEventMemberLink: async (eventId, eventMemberId, emailAddress) => {
    const body = {
      eventId,
      eventMemberId,
      emailAddress
    };

    const response = await axios.post('/messenger/event-member-link/test', body, { baseURL });

    return response.data.data;
  },

  sendRaffleActivationRequest: async (eventId) => {
    const body = {
      eventId
    };

    const response = await axios.post('/messenger/event-activation-request/send', body, { baseURL });

    return response.data.data;
  },

  getContacts: async (organizationId, params) => {
    const response = await axios.get(`/contacts/${organizationId}`, { baseURL, params });

    return response.data.data;
  },
  getHistory: async (params) => {
    const response = await axios.get(`/history`, {
      params,
      baseURL
    });

    return response.data.data;
  },
  listSchedules: async (params) => {
    const response = await axios.get(`/v2/schedules`, {
      params,
      baseURL
    });

    return response.data.schedules;
  },
  updateSchedule: async (organizationId, body) => {
    const response = await axios.patch(`/v2/${organizationId}/schedules`, body, { baseURL });

    return response.data.data;
  },
  deleteSchedule: async (organizationId, body) => {
    const response = await axios.delete(`${baseURL}/v2/${organizationId}/schedules`, {
      data: { messageCenterScheduleName: body }
    });

    return response.data.data;
  }
};
