<template>
  <div>
    <b-alert v-if="error" show dismissible variant="danger">{{ error }}</b-alert>
    <multiselect
      v-model="selected"
      :options="options"
      label="name"
      track-by="id"
      :searchable="true"
      @search-change="searchChanged"
      @remove="reset"
      :internal-search="false"
      :loading="loading"
      :allow-empty="true"
      :placeholder="placeholderText"
      :name="inputName"
    >
      <template #noResult> No events found matching search. </template>
    </multiselect>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect';

import EventService from '@/lib/event-service-v2';
export default {
  components: { Multiselect },
  props: {
    defaultOption: null,
    value: null,
    inputName: {
      type: String
    },
    placeholderText: {
      type: String,
      default: 'Find a Raffle'
    },
    organizationId: {
      type: String
    },
    event: {
      type: Object
    }
  },
  data() {
    return {
      error: null,
      loading: false,
      options: [],
      selected: this.event ? this.event.name : null
    };
  },
  watch: {
    selected: function () {
      if (this.initialized && this.selected) {
        this.$emit('input', this.selected?.id);
      }
      this.$emit('eventSelected', this.selected);
    },
    event: function () {
      this.selected = this.event ? this.event : null;
    },
    organizationId: function () {
      this.options = [];
      this.selected = null;
      this.search();
    },
    value: function (newVal) {
      if (!newVal) {
        this.selected = null;
        this.setOptions([]);
        this.search();
      } else if (newVal !== this.selected?.id) {
        this.loadCurrent();
      }
    }
  },
  async mounted() {
    this.setOptions([]);
    this.selected = this.event ? this.event : null;
    await this.loadCurrent();
    this.initialized = true;
    this.search();
  },
  methods: {
    async loadCurrent() {
      if (!this.value) {
        return;
      }
      this.loading = true;
      const event = await EventService.retrieveEvent(this.value);

      this.setOptions([event]);
      this.selected = event;

      this.loading = false;
    },

    async searchChanged(query) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(() => this.search(query), 300);
    },

    async search(query) {
      this.loading = true;

      const params = {};

      if (query) {
        params.search = query;
      }

      if (this.organizationId) {
        params.organizationId = this.organizationId;
      }

      try {
        const response = await EventService.listEvents(params);
        const options = [];

        for (const event of response.data) {
          options.push(event);
        }
        this.setOptions(options);
      } catch (error) {
        this.error = error;
      }
      this.loading = false;
    },

    setOptions(options) {
      let result = [];

      if (this.defaultOption) {
        result.push(this.defaultOption);
      }

      result = [...result, ...options];

      this.options = result;
    },

    reset() {
      this.selected = null;
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
