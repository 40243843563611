<template>
  <div>
    <b-modal
      id="send-test-email-modal"
      v-model="modalShow"
      title="Send Test Email"
      @show="resetForm"
      @hidden="resetForm"
      @ok="handleOk"
      no-close-on-backdrop
    >
      <b-form @submit.stop.prevent="onSubmit">
        <b-form-group label="Email Address" label-for="input-email" :invalid-feedback="veeErrors.first('input-email')">
          <b-form-input
            name="input-email"
            v-model="email"
            v-validate="{ required: true, email: true }"
            :state="validateState('input-email')"
            aria-describedby="input-email-feedback"
            data-vv-as="email"
            trim
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import MessageCenter from '@/lib/message-center';

export default {
  props: ['event', 'eventMember', 'template', 'settings'],
  data() {
    return {
      modalShow: false,
      email: null
    };
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },

    resetForm() {
      this.email = null;

      this.$nextTick(() => {
        this.$validator.reset();
      });
    },

    handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    },

    async onSubmit() {
      const valid = await this.$validator.validateAll();
      if (!valid) {
        return;
      }

      try {
        if (this.template.value === 'raffleReminder') {
          await MessageCenter.sendTestRaffleReminder(this.event.id, this.email);
        } else if (this.template.value === 'winnerPicked') {
          await MessageCenter.sendTestWinnerPicked(
            this.event.id,
            this.email,
            this.settings.includeNextRaffleLink,
            this.settings.nextRaffleLink
          );
        } else if (this.template.value === 'eventMemberLink') {
          await MessageCenter.sendTestEventMemberLink(this.event.id, this.eventMember?.id, this.email);
        }

        this.$emit('success', 'Test email sent!');

        this.modalShow = false;
      } catch (error) {
        this.$emit('error', 'Failed to send test email');
      }
    }
  }
};
</script>

<style scoped></style>
