<template>
  <span
    v-b-tooltip.hover
    :title="
      formatDateTime(parseISO(dateTime), {
        format: 'dateTime',
        timeZone: timeZone,
        displayTimeZone: true
      })
    "
    class="border-dashed border-b-2 border-gray-500 cursor-pointer"
  >
    {{ formatDateTime(parseISO(dateTime), { timeZone: timeZone, displayTimeZone: true }) }}
  </span>
</template>

<script>
export default {
  props: {
    dateTime: {
      type: String,
      required: true
    },
    timeZone: {
      type: String,
      required: true
    }
  }
};
</script>
