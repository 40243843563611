<template>
  <b-container class="mb-7">
    <b-tabs pills vertical>
      <b-tab title="Messenger" lazy>
        <b-row class="ml-4">
          <b-col>
            <h1>
              <span class="text-muted">Message Center ></span>
              Messenger
            </h1>
          </b-col>
        </b-row>
        <Messenger />
      </b-tab>
      <b-tab title="Schedules" lazy>
        <b-row class="ml-4">
          <b-col>
            <h1>
              <span class="text-muted">Messenger ></span>
              Schedules
            </h1>
          </b-col>
        </b-row>
        <MessageCenterScheduleList ref="schedules" style="margin-top: 2rem" />
      </b-tab>
    </b-tabs>
  </b-container>
</template>

<script>
import Messenger from '@/components/Messenger';
import MessageCenterScheduleList from '@/components/MessageCenterScheduleList';

export default {
  name: 'MessageCenter',
  components: { Messenger, MessageCenterScheduleList }
};
</script>

<style scoped>
.container {
  text-align: left;
  margin-left: 115px;
  max-width: 90%;
}
</style>
